<template>
  <div>
    <div class="relative max-w-lg px-6 pt-12 pb-8 mt-1 overflow-y-auto w-140 max-h-75vh">
      <div
        v-for="(result, index) in searchResults"
        :key="result.id"
        class="flex items-center justify-between w-full pt-1 pb-2 border-b select-none"
        :class="{
          'border-t': index === 0,
        }"
      >
        <div class="flex-shrink-0 mr-3 -mb-4">
          <RadioButton
            :value="selectedId"
            :native-value="result.id"
            @change="selectedId === result.id ? selectedId = null : selectedId = result.id"
          />
        </div>
        <div
          class="flex-1 cursor-pointer"
          @click="selectedId === result.id ? selectedId = null : selectedId = result.id"
        >
          <div class="font-bold leading-tight">
            <template>
              <span v-if="result.person.firstname && result.person.firstname.length > 0">{{ result.person.firstname }} </span>
              <span v-if="result.person.lastname && result.person.lastname.length > 0">{{ result.person.lastname }}</span>
            </template>
            <template
              v-if="(!result.person.firstname || !result.person.firstname.length > 0)
              && (!result.person.lastname || !result.person.lastname.length > 0)"
            >
              {{ $t('noNameAvailable') }}
            </template>
          </div>
          <div class="text-xs text-gray-800">
            <div v-if="result.person.birthday">
              {{ $t('bornOnDate', { d: $d(new Date(result.person.birthday), 'short') }) }}
            </div>
            <div v-if="result.address">
              <span v-if="result.address.street && result.address.street.length > 0">
                {{ result.address.street }},
              </span>
              <span>
                {{ result.address.postcode }}
              </span>
              <span>
                {{ result.address.city }}
              </span>
            </div>
            <div v-if="result.contact">
              {{ result.contact.email }}
            </div>
          </div>
        </div>
        <!-- <div>
          <button
            class="py-3 text-primary-500 focus:outline-none text:bg-primary-700"
            @click="$emit('choose', result)"
          >
            {{ $t('transfer') }}
          </button>
        </div> -->
      </div>
    </div>
    <div class="flex w-full px-6 pb-4 mt-4 bg-white">
      <Button class="flex-1 mr-2" @click="$emit('close')">{{ $t('cancel') }}</Button>
      <Button
        class="flex-1 ml-2"
        primary
        :disabled="selectedId === null"
        @click="onTransfer"
      >
        {{ $t('transfer') }}
      </Button>
    </div>
    <button
      class="absolute top-0 right-0 mt-1 mr-4 bg-white focus:outline-none"
      @click="$emit('close')"
    >
      <Icon name="close" font-classes="text-3xl" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    searchResults: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedId: null,
    };
  },

  watch: {
    searchResults: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.length !== oldVal.length) {
          this.selectedId = null;
        }
      },
    },
  },

  methods: {
    onTransfer() {
      let transferResult = null;
      this.searchResults.forEach((result) => {
        if (result.id === this.selectedId) {
          transferResult = result;
        }
      });
      if (transferResult) {
        this.$emit('transfer', transferResult);
      }
    },
  },
};
</script>
