<template>
  <SafeForm @submit.prevent="onContinue">
    <div>
      <SelectField
        v-model="customer.title"
        :label="$t('title')"
        id="field_title"
        floating
      >
        <option :value="null">–</option>
        <option value="dr">{{ $t('dr') }}</option>
        <option value="prof">{{ $t('prof') }}</option>
        <option value="dipl-ing">{{ $t('diplIng') }}</option>
      </SelectField>
      <TextField
        v-model="customer.firstname"
        :label="$t('firstName')"
        id="field_firstname"
        floating
        required
        @change="search"
      />
      <TextField
        v-model="customer.lastname"
        :label="$t('lastName')"
        id="field_lastname"
        floating
        required
        @change="search"
      />
      <div class="flex -mb-2">
        <div class="flex flex-1">
          <DatePicker
            v-model="customer.birthday"
            :label="$t('birthday')"
            id="birthday"
            floating
            class="w-full"
            margin="0"
            :max="todayIso"
            :max-decade="Number(todayIso.split('-')[0])"
            year-select
            required
            @input="search"
          />
        </div>
        <div v-if="customer.age !== null && customer.age >=  0" class="flex flex-shrink-0 ml-4 pt-px mt-px">
          {{ $tc('yearsOld', customer.age) }}
        </div>
      </div>
      <div class="mb-4">
        <Button
          v-if="!$store.state.contract.newContract.selectedCustomerId"
          class="-ml-4"
          text
          :disabled="searchResults.length === 0"
          @click="openSearchResults"
        >
          {{ $tc('foundNumberOfResults', searchResults.length) }}
        </Button>
      </div>
      <SelectField
        v-model="customer.sex"
        :label="$t('sex')"
        id="field_sex"
        floating
        required
        @focus="closeDatepicker"
      >
        <option :value="1">{{ $t('female') }}</option>
        <option :value="0">{{ $t('male') }}</option>
      </SelectField>
      <SelectField
        v-model="customer.country"
        :label="$t('country')"
        id="field_country"
        floating
        required
        @input="onCountryChange"
      >
        <option
          v-for="country in sortedCountries"
          :key="country[1]"
          :value="country[0]"
          :disabled="country[0] === 0"
        >
          {{ country[1] }}
        </option>
      </SelectField>
      <div class="flex">
        <div class="flex w-1/3 mr-2">
          <TextField
            v-model="customer.postcode"
            :label="$t('postcode')"
            id="field_postcode"
            floating
            inputmode="numeric"
            class="w-full"
            required
            @input="onZipInput"
          />
        </div>
        <div class="flex w-2/3 ml-2">
          <TextField
            v-model="customer.city"
            :label="$t('city')"
            id="field_city"
            floating
            class="w-full"
            required
          />
        </div>
      </div>
      <div class="flex">
        <div class="flex w-2/3 mr-2">
          <TextField
            v-model="customer.street"
            :label="$t('street')"
            id="field_street"
            floating
            class="w-full"
            required
          />
        </div>
        <div class="flex w-1/3 ml-2">
          <TextField
            v-model="customer.houseNumber"
            :label="$t('houseNumber')"
            id="field_houseNumber"
            floating
            inputmode="numeric"
            class="w-full"
            required
          />
        </div>
      </div>
      <!-- Only show these fields if the selected country is Austria -->
      <div v-if="countryIsAustria" class="flex">
        <div class="flex w-1/2 mr-2">
          <TextField
            v-model="customer.streetAddition1"
            :label="$t('stairs')"
            id="field_stairs"
            floating
            class="w-full"
          />
        </div>
        <div class="flex w-1/2 ml-2">
          <TextField
            v-model="customer.streetAddition2"
            :required="streetAddition1IsNotEmptyAT"
            :label="$t('door')"
            id="field_door"
            floating
            class="w-full"
          />
        </div>
      </div>
      <TextField
        v-model="customer.addition"
        :label="$t('addition')"
        id="field_addition"
        floating
      />
      <TextField
        v-model="customer.email"
        :label="$t('email')"
        id="field_email"
        inputmode="email"
        type="email"
        floating
        required
      />
      <div class="relative" :class="{'mb-4': !showExtraPhoneField}">
        <div class="flex">
          <div class="flex flex-shrink mr-1">
            <SelectField
              v-model="customer.phoneCountryCode"
              class="w-full"
              label="LKZ"
              id="field_country_code"
              floating
              required
            >
              <option
                v-for="country in sortedCountries"
                :key="`phoneCountryCode_${country[0]}`"
                :value="country[0] === 0 ? 0 : `+${country[2].code}`"
                :disabled="country[0] === 0"
              >
                <template v-if="country[0] === 0">{{ country[1] }}</template>
                <template v-else>{{ country[2].label }} +{{ country[2].code }}</template>
              </option>
            </SelectField>
          </div>
          <div class="flex flex-grow mr-2">
            <TextField
              v-model="customer.phone"
              :label="$t('phone')"
              id="field_phone"
              inputmode="tel"
              floating
              class="w-full"
              required
            />
          </div>
          <div class="flex w-1/4 ml-2">
            <SelectField
              v-model="phone1type"
              class="w-full"
              @input="(value) => value === 'mobile' ? phone2type = 'landline' : phone2type = 'mobile'"
            >
              <option value="mobile">{{ $t('mobile') }}</option>
              <option value="landline">{{ $t('landline') }}</option>
            </SelectField>
          </div>
        </div>
        <Button
          v-if="!showExtraPhoneField"
          class="-mt-4 -ml-4"
          text
          @click="showExtraPhoneField = true"
        >
          + {{ $t('addAdditionalPhone') }}
        </Button>
      </div>
      <div
        v-if="showExtraPhoneField"
        class="relative mb-4"
      >
        <div class="flex">
          <div class="flex flex-shrink mr-1">
            <SelectField
              v-model="customer.phoneWorkCountryCode"
              class="w-full"
              label="LKZ"
              floating
              :required="customer.phoneWork && showExtraPhoneField"
            >
              <option
                v-for="country in sortedCountries"
                :key="`phoneWorkCountryCode_${country[0]}`"
                :value="country[0] === 0 ? 0 : `+${country[2].code}`"
                :disabled="country[0] === 0"
              >
                <template v-if="country[0] === 0">{{ country[1] }}</template>
                <template v-else>{{ country[2].label }} +{{ country[2].code }}</template>
              </option>
            </SelectField>
          </div>
          <div class="flex flex-grow mr-2">
            <TextField
              v-model="customer.phoneWork"
              inputmode="tel"
              :label="$t('phone')"
              floating
              class="w-full"
            />
          </div>
          <div class="flex w-1/4 ml-2">
            <SelectField
              v-model="phone2type"
              class="w-full"
              @input="(value) => value === 'mobile' ? phone1type = 'landline' : phone1type = 'mobile'"
            >
              <option value="mobile">{{ $t('mobile') }}</option>
              <option value="landline">{{ $t('landline') }}</option>
            </SelectField>
          </div>
        </div>
        <Button
          v-if="showExtraPhoneField"
          class="-mt-4 -ml-4"
          text
          @click="showExtraPhoneField = false; customer.phoneWork = null;"
        >
          – {{ $t('removeAdditionalPhone') }}
        </Button>
      </div>
      <div v-if="!customer.age || customer.age >= 18">
        <CheckBox
          v-model="customer.showLegalGuardian"
          class="mb-4"
        >
          {{ $t('needsLegalGuardian') }}
        </CheckBox>
      </div>
      <TextField
        v-if="customer.showLegalGuardian || (customer.age && customer.age < 18)"
        v-model="customer.legalGuardian"
        :label="customer.showLegalGuardian ? $t('legalGuardianAlt') : $t('legalGuardian')"
        floating
      />
    </div>
    <div class="flex flex-shrink w-full mt-6 pb-12">
      <Button class="mr-2 w-full" to="starting-conditions">
        {{ $t('back') }}
      </Button>
      <Button class="ml-2 w-full" primary type="submit">
        {{ $t('continue') }}
      </Button>
    </div>
    <Portal to="Modal">
      <Modal
        v-model="searchResultsModal"
      >
        <CustomerResultsModal
          :search-results="searchResults"
          @close="searchResultsModal = false"
          @transfer="chooseCustomerResult"
        />
      </Modal>
    </Portal>
  </SafeForm>
</template>

<script>
import { format, differenceInYears } from 'date-fns';
import { getTitleString } from '@/utils/transformToBookingDto.util';
import CustomerResultsModal from '../../../components/CustomerResultsModal.vue';

export default {
  components: {
    CustomerResultsModal,
  },

  data() {
    return {
      customer: {
        firstname: null,
        lastname: null,
        birthday: null,
        sex: null,
        street: null,
        houseNumber: null,
        stairs: null,
        door: null,
        postcode: null,
        city: null,
        email: null,
        phone: null,
        phoneCountryCode: null,
        phoneWork: null,
        phoneWorkCountryCode: null,
        legalGuardian: null,
        addition: null,
        showLegalGuardian: false,
        age: null,
      },
      phone1type: 'mobile',
      phone2type: 'landline',
      showExtraPhoneField: false,
      searchResultsModal: false,
    };
  },

  computed: {
    /**
     * If the country is Austria AND streetAddition1 is not empty
     * streetAddition2 must be set as well.
     * @returns {boolean}
     */
    streetAddition1IsNotEmptyAT() {
      if (!this.countryIsAustria) {
        return false;
      }
      return this.customer.streetAddition1 !== null
             && this.customer.streetAddition1 !== '';
    },
    todayIso() {
      return format(new Date(), 'yyyy-MM-dd');
    },
    searchResults() {
      return this.$store.state.customer.searchResults;
    },
    sortedCountries() {
      const primeCountries = this.$t('countries')
        .filter((country) => ([1, 4, 9].includes(country[0])))
        .sort((a, b) => a[0] - b[0]);
      const sortedCountries = this.$t('countries')
        .filter((country) => (![1, 4, 9].includes(country[0])))
        .sort((a, b) => {
          if (a[1] < b[1]) { return -1; }
          if (a[1] > b[1]) { return 1; }
          return 0;
        });
      return [...primeCountries, [0, '----------'], ...sortedCountries];
    },
    telephoneCountryCodes() {
      return this.$t('countries')
        .map((country) => `+${country[2].code}`);
    },
    countryIsAustria() {
      const countryId = parseInt(this.customer.country, 10);
      return countryId === 4; // Id 4 is Austria
    },
  },

  watch: {
    'customer.birthday': {
      handler(newVal) {
        const today = new Date();
        const birth = new Date(newVal);
        this.customer.age = differenceInYears(today, birth);
      },
    },
  },

  created() {
    this.customer = this.$store.state.contract.newContract.customer;
    this.phone1type = this.$store.state.contract.newContract.phoneType || 'mobile';
    this.phone2type = this.$store.state.contract.newContract.phoneWorkType || 'landline';
    if (this.customer.phoneWork) {
      this.showExtraPhoneField = true;
    } else {
      this.showExtraPhoneField = false;
    }
  },

  methods: {
    onContinue() {
      this.$store.commit('updateContractForm', {
        customer: this.customer,
        phoneType: this.phone1type,
        phoneWorkType: this.phone2type,
      });
      this.$router.push('accounting');
    },
    onZipInput(value) {
      if (value.length === 5) {
        this.$store.dispatch('getCityForZip', value)
          .then((resp) => {
            if (resp.data.city.length > 0) {
              const [city] = resp.data.city;
              this.customer.city = city;
            }
          });
      }
    },
    search() {
      const {
        firstname,
        lastname,
        birthday,
      } = this.customer;
      if (firstname && lastname && birthday) {
        this.$store.dispatch('searchCustomer', {
          firstname,
          lastname,
          birthday,
        });
      }
    },
    openSearchResults() {
      this.searchResultsModal = true;
    },
    chooseCustomerResult(result) {
      let phoneNumber = result.contact.mobile;
      let phoneWorkNumber = result.contact.phone || result.contact.phoneWork;
      let phoneCountryCode = null;
      let phoneWorkCountryCode = null;
      if (phoneNumber) {
        let countryCodeTest1 = phoneNumber.substring(0, 3);
        if (this.telephoneCountryCodes.indexOf(countryCodeTest1) > -1) {
          phoneNumber = phoneNumber.substring(3, phoneNumber.length);
          phoneCountryCode = countryCodeTest1;
        } else {
          countryCodeTest1 = phoneNumber.substring(0, 4);
          if (this.telephoneCountryCodes.includes(countryCodeTest1)) {
            phoneNumber = phoneNumber.substring(4, phoneNumber.length);
            phoneCountryCode = countryCodeTest1;
          }
        }
      }

      if (phoneWorkNumber) {
        let countryCodeTest1 = phoneWorkNumber.substring(0, 3);
        if (this.telephoneCountryCodes.indexOf(countryCodeTest1) > -1) {
          phoneWorkNumber = phoneWorkNumber.substring(3, phoneWorkNumber.length);
          phoneWorkCountryCode = countryCodeTest1;
        } else {
          countryCodeTest1 = phoneWorkNumber.substring(0, 4);
          if (this.telephoneCountryCodes.includes(countryCodeTest1)) {
            phoneWorkNumber = phoneWorkNumber.substring(4, phoneWorkNumber.length);
            phoneWorkCountryCode = countryCodeTest1;
          }
        }
      }

      this.searchResultsModal = false;
      this.customer.title = getTitleString(result.person.title);
      this.customer.sex = result.person.sex;
      this.customer.firstname = result.person.firstname;
      this.customer.lastname = result.person.lastname;
      this.customer.birthday = result.person.birthday || this.customer.birthday;
      this.customer.street = result.address.street;
      this.customer.houseNumber = result.address.houseNumber;
      this.customer.streetAddition1 = result.address.streetAddition1; // In AT this is "Stiege"
      this.customer.streetAddition2 = result.address.streetAddition2; // In AT this is "Tür"
      this.customer.postcode = result.address.postcode;
      this.customer.city = result.address.city;
      this.customer.country = result.address.country ? result.address.country : null;
      this.customer.addition = result.address.addition;
      this.customer.email = result.contact.email;
      this.customer.phone = phoneNumber;
      this.customer.phoneCountryCode = phoneCountryCode;
      this.customer.phoneWork = phoneWorkNumber;
      this.customer.phoneWorkCountryCode = phoneWorkCountryCode;
      this.customer.legalGuardian = result.person.legalGuardian;
      if (this.customer.legalGuardian && this.customer.legalGuardian.length > 0) {
        this.customer.showLegalGuardian = true;
      }
      if (this.customer.phoneWork) {
        this.showExtraPhoneField = true;
      }
      this.phone1type = 'mobile';
      this.phone2type = 'landline';
      if (this.customer.country) {
        this.onCountryChange(this.customer.country);
      }
      this.$store.commit('updateContractForm', {
        selectedCustomerId: result.id,
        customer: this.customer,
        phoneType: this.phone1type,
        phoneWorkType: this.phone2type,
        accounting: {
          ...this.$store.state.contract.newContract.accounting,
          ibanBank: result.bank.ibanBank,
          bicBank: result.bank.bicBank,
          accountHolderBank: result.bank.accountHolderBank,
          nameBank: result.bank.nameBank,
        },
      });
    },
    onCountryChange(value) {
      const [country] = this.$t('countries')
        .filter((countryVal) => countryVal[0] === Number(value));
      this.customer.phoneCountryCode = `+${country[2].code}`;
      this.customer.phoneWorkCountryCode = `+${country[2].code}`;
    },
    closeDatepicker(e) {
      e.target.click();
    },
  },
};
</script>
